<template>
  <div class="Riichicitystore">
    <main>
      <img src="@/assets/image/home/riichicitystore/loading_logo.png" alt="" />
      <p>{{ $t("riichicitystore.p2") }}</p>
    </main>
  </div>
</template>

<script>
export default {
  name: "Riichicitystore",
  data() {
    return {
      timer: null,
      storePaths: ["/pray/recharge", "/sudoku", "/frank"],
    };
  },
  mounted() {
    let redirectUrl = `${process.env.VUE_APP_SHOP_URL}`;
    const params = "?from=official";
    this.timer = setTimeout(() => {
      const path = this.$route.query.path;
      if (path && this.storePaths.includes(path)) {
        redirectUrl += path;
      } else {
        redirectUrl += "/shop";
      }
      redirectUrl += params;
      location.replace(redirectUrl);
    }, 500);
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.Riichicitystore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #84d5ff;
  main {
    img {
      width: 210px;
      margin-bottom: 10px;
    }
    p {
      font-family: SourceHanSansSC-Bold;
      font-size: 36px;
      text-align: center;
      color: #ffffff;
    }
    @media screen and (max-width: 768px) {
      img {
        width: 210px;
      }

      p {
        font-family: SourceHanSansSC-Bold;
        font-size: 24px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}
</style>
